<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Correção de Prova">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <div class="d-flex flex-column align-center flex-md-row comment-row mt-0 pa-3">
              <div class="pa-2">
                <img
                  src="https://cdn.vuetifyjs.com/images/lists/3.jpg"
                  alt="user"
                  width="50"
                  class="rounded-circle"
                />
              </div>
              <div class="comment-text w-100 pa-3 pb-0">
                <h3 class="font-weight-regular">Lucinéia Percigili</h3>
                <span class="d-block mb-2 subtitle-2 font-weight-regular">lucineia@gmail.com</span>
                <p class="body-2 my-0">
                  <v-icon class="body-2">mdi-text-box-check-outline</v-icon>Prova de Matemática - Frações
                </p>
                <p class="body-2 my-0">
                  <v-icon class="body-2">mdi-alarm</v-icon>45:30
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-row class="flex flex-column flex-sm-row align-center justify-center">
              <v-col cols="10" sm="6">
                <v-card class="text-center" outlined elevation="0">
                  <v-card-title class="justify-center blue darken-1 white--text py-2 mb-4">Nota</v-card-title>
                  <v-card-text>
                    <h1 class="text-primary">3.0</h1>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="10" sm="6">
                <v-card class="text-center" outlined elevation="0">
                  <v-card-title
                    class="justify-center blue lighten-2 white--text py-2 mb-4"
                  >Valor da Prova</v-card-title>
                  <v-card-text>
                    <h1 class="grey--text">5.0</h1>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-divider />
            <v-list flat>
              <v-list-item-group>
                <v-list-item @click="dialog = !dialog">
                  <v-list-item-icon>
                    <v-icon>mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Na natureza, a água, por meio de processos físicos, passa
                      pelas fases líquida, gasosa e sólida perfazendo o ciclo
                      hidrológico. A distribuição da água na
                      Ter
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>Nota: 1.00</v-list-item-action-text>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-divider />
          </v-col>
        </v-row>
      </v-container>
    </base-card>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Corrigir Questão</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="mt-6">
          <v-row>
            <v-col cols="12" md="8">
              <span class="math-tex">/(x_a+y^2/)</span>
              <v-responsive :aspect-ratio="16/9" v-if="video">
                <iframe
                  :src="video"
                  allowfullscreen="allowfullscreen"
                  style="width: 100%;height:100%"
                ></iframe>
              </v-responsive>
              <div v-html="text" class="my-6" />
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-radiobox-blank</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Resposta 1</v-list-item-title>
                </v-list-item>
                <v-list-item class="correct-answer">
                  <v-list-item-icon>
                    <v-icon>mdi-radiobox-blank</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Resposta 2</v-list-item-title>
                </v-list-item>
                <v-list-item class="incorrect-answer">
                  <v-list-item-icon>
                    <v-icon>mdi-radiobox-marked</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Resposta 3</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-radiobox-blank</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Resposta 4</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="4" class="px-4">
              <p class="body-2 my-0 font-weight-medium">Tempo gasto na resolução</p>
              <p class="body-2 my-0">
                <v-icon class="body-2">mdi-alarm</v-icon>00:00:36
              </p>
              <p class="body-2 mb-2 mt-4 font-weight-medium">Correção</p>
              <v-text-field
                label="Acerto (%)"
                background-color="transparent"
                v-mask="'###'"
                outlined
                dense
              ></v-text-field>
              <v-textarea
                label="Comentários"
                background-color="transparent"
                v-mask="'###'"
                outlined
                dense
              ></v-textarea>
              <v-checkbox class="py-0 my-0" label="Aplicar correção para todos os alunos"></v-checkbox>
              <v-btn color="primary" elevation="0" class="w-100">Salvar</v-btn>
              <v-btn
                color="primary"
                outlined
                elevation="0"
                class="w-100 mt-2"
                @click="dialog = false"
              >Fechar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Vue from 'vue'
// import MathJax, { initMathJax, renderByMathjax } from 'mathjax-vue'
const options = {
  tex: {
    inlineMath: [['/(', '/)']]
  }
}
// function onMathJaxReady () {
//   const elements = document.getElementsByClassName('math-tex')
//   console.log(elements)
//   renderByMathjax(elements)
// }
// initMathJax(options, onMathJaxReady)
// Vue.use(MathJax)
// import '../../../public/js/tinymce-mathjax/es5/tex-chtml.js'
// import MathJax from 'mathjax'

export default {
  data: () => ({
    dialog: false,
    page: {
      title: 'Correção de Prova'
    },
    video: 'https://www.youtube.com/embed/a0ZEeW-fG9s?ab_channel=Caf',
    text: '<p style="text-align: center;">Teste de questão vai aqui mesmo.&nbsp;<em>Teste.&nbsp;</em><span style="color: #e03e2d;">Teste.</span></p> <p id="editor-output" style="text-align: center;"><span style="color: #e03e2d;"><span class="math-tex">$$ (x+2)^2 $$</span></span></p>',
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Aplicações de Prova',
        disabled: false,
        to: '/application/list'
      },
      {
        text: 'Prova de Matemática - Frações',
        disabled: false,
        to: '/application/edit'
      },
      {
        text: 'Correção de Prova',
        disabled: false,
        to: '/application/resolution/edit'
      }
    ]
  })
  // methods: {
  //   // updated () {
  //   //   MathJax.typeset()
  //   // }
  // },
  // created () {
  //   var scripts = [
  //     'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-MML-AM_CHTML'
  //   ]
  //   scripts.forEach(script => {
  //     const tag = document.createElement('script')
  //     tag.setAttribute('src', script)
  //     document.head.appendChild(tag)
  //   })
  // },
  // mounted () {
  //   setTimeout(() => {
  //     window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, 'math-tex'])
  //   }, 2000)
  // }
}
</script>
<style scoped>
.incorrect-answer {
  background-color: #ef9a9a;
}
.correct-answer {
  background-color: #81c784;
}
</style>
